/* eslint-disable  */ 
import React, { useReducer, Dispatch, useContext, ReactNode } from 'react';

import {
  StateType,
  Type,
  Variant,
  Action,
  initialState,
  reducer,
  NotificationType,
} from './state';

const StateContext = React.createContext<StateType>(initialState);
const DispatchContext = React.createContext<Dispatch<Action>>((): void => {});

type Props = {
  children: ReactNode;
};

const NotificationProvider = (props: Props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
};

const useNotificationsState = () => {
  const state = useContext(StateContext);
  return state;
};

const useNotificationDispatch = () => {
  const dispatch = useContext(DispatchContext);
  return dispatch;
};

export {
  NotificationProvider,
  useNotificationsState,
  useNotificationDispatch, Variant,
  Type
};  export type { NotificationType };

