/* eslint-disable  */

import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { DataMessage } from 'amazon-chime-sdk-js';

import ChimeSdkWrapper from '../chime/ChimeSdkWrapper';
import getChimeContext from '../context/getChimeContext';
// import getUIStateContext from "../context/getUIStateContext";
import getMeetingStatusContext from '../context/getMeetingStatusContext';
import getGlobalVarContext from '../context/getGlobalVarContext';
import ClassMode from '../enums/ClassMode';
import MessageTopic from '../enums/MessageTopic';
import MeetingStatus from '../enums/MeetingStatus';
// import common from "../constants/common.json";
import routes from '../constants/routes.json';
import {
  useNotificationDispatch,
  Type as NotifType,
} from '../providers/NotificationProvider';
import localStorageKeys from '../constants/localStorageKeys.json';

export default function useRemoteControl() {
  const chime: ChimeSdkWrapper | null = useContext(getChimeContext());
  const { meetingStatus } = useContext(getMeetingStatusContext());
  const notifDispatch = useNotificationDispatch();
  const intl = useIntl();

  const { globalVar, updateGlobalVar } = useContext(getGlobalVarContext());
  const { classMode } = globalVar;
  // const [state] = useContext(getUIStateContext());
  const navigate = useNavigate();
  const localUserId =
    chime?.meetingSession?.configuration?.credentials?.attendeeId;

  useEffect(() => {
    if (meetingStatus !== MeetingStatus.Succeeded) {
      return;
    }

    const callback = async (message: DataMessage) => {
      if (classMode === ClassMode.Teacher) {
        return;
      }
      const { topic } = message;
      const { focus, targetId, data } = message.json();

      switch (topic) {
        case MessageTopic.RemoteMuteUnmute:
          if (targetId === localUserId) {
            // chime?.audioVideo?.realtimeSetCanUnmuteLocalAudio(!focus);
            if (focus) {
              chime?.audioVideo?.realtimeMuteLocalAudio();
              notifDispatch({
                type: NotifType.REMOTE_MUTE,
                payload: {
                  message: intl.formatMessage({
                    id: 'Notification.remoteMute',
                  }),
                },
              });
            } else {
              chime?.audioVideo?.realtimeUnmuteLocalAudio();
              notifDispatch({
                type: NotifType.REMOTE_UNMUTE,
                payload: {
                  message: intl.formatMessage({
                    id: 'Notification.remoteUnMute',
                  }),
                },
              });
            }
          }
          break;
        case MessageTopic.RemoveAttendee:
          if (targetId === localUserId) {
            if (focus) {
              await chime?.leaveRoom(false);
              // window.location.href = `${common.domain}complete`
              navigate(routes.MAIN);
            }
          }
          break;
        case MessageTopic.RemoteVideoOnOff:
          if (targetId === localUserId) {
            try {
              if (!chime?.currentVideoInputDevice) {
                throw new Error('currentVideoInputDevice does not exist');
              }
              await chime?.chooseVideoInputDevice(
                chime?.currentVideoInputDevice
              );
              if (focus) {
                chime?.audioVideo?.startLocalVideoTile();
                updateGlobalVar('localVideo', true);
                notifDispatch({
                  type: NotifType.REMOTE_VIDEO_ENABLED,
                  payload: {
                    message: intl.formatMessage({
                      id: 'Notification.remoteVideoStart',
                    }),
                  },
                });
              } else {
                chime?.audioVideo?.stopLocalVideoTile();
                updateGlobalVar('localVideo', false);
                notifDispatch({
                  type: NotifType.REMOTE_VIDEO_DISABLED,
                  payload: {
                    message: intl.formatMessage({
                      id: 'Notification.remoteVideoStop',
                    }),
                  },
                });
              }
            } catch (error) {
              console.error(error);
            }
          }
          break;
        case MessageTopic.Focus:
          chime?.audioVideo?.realtimeSetCanUnmuteLocalAudio(!focus);
          if (focus) {
            chime?.audioVideo?.realtimeMuteLocalAudio();
            notifDispatch({
              type: NotifType.REMOTE_AUTO_FOCUS,
              payload: {
                message: intl.formatMessage({ id: 'Notification.turnOnFocus' }),
              },
            });
          } else {
            notifDispatch({
              type: NotifType.REMOTE_AUTO_FOCUS,
              payload: {
                message: intl.formatMessage({
                  id: 'Notification.turnOffFocus',
                }),
              },
            });
          }
          break;
        case MessageTopic.MuteAll:
          chime?.audioVideo?.realtimeSetCanUnmuteLocalAudio(focus);
          if (focus) {
            chime?.audioVideo?.realtimeMuteLocalAudio();
            notifDispatch({
              type: NotifType.REMOTE_AUTO_FOCUS,
              payload: {
                message: intl.formatMessage({ id: 'Notification.remoteMute' }),
              },
            });
          }
          break;
        case MessageTopic.ScreenSharePermit:
          if (targetId === localUserId) {
            updateGlobalVar('screenSharePermit', focus);
            if (focus) {
              notifDispatch({
                type: NotifType.SCREEN_SHARE_PERMIT,
                payload: {
                  message: intl.formatMessage({
                    id: 'Notification.screenSharePermit',
                  }),
                },
              });
            } else {
              chime?.audioVideo?.stopContentShare();
              notifDispatch({
                type: NotifType.SCREEN_SHARE_PERMIT,
                payload: {
                  message: intl.formatMessage({
                    id: 'Notification.screenShareNotPermit',
                  }),
                },
              });
            }
          }
          break;
        case MessageTopic.EndMeeting:
          if (focus) {
            await chime?.leaveRoom(false);
            navigate(routes.MAIN);
          }
          break;
        case MessageTopic.ExtendMeetingTime:
          const parsedData = JSON.parse(data);
          const joinInfo = JSON.parse(
            localStorage.getItem(localStorageKeys.MEETING_CONFIG) as string
          );
          joinInfo.EndTime = parsedData.endTime;
          joinInfo.IsMeetingExtended = parsedData.isMeetingExtended;
          localStorage.setItem(
            localStorageKeys.MEETING_CONFIG,
            JSON.stringify(joinInfo)
          );
          notifDispatch({
            type: NotifType.TIME_EXTENSION_MESSAGE,
            payload: {
              message: intl.formatMessage(
                { id: 'Classroom.timeExtensionMsg' },
                {
                  time: parsedData.extTime,
                }
              ),
            },
          });
          updateGlobalVar(
            'isTimeExtended',
            parsedData.isMeetingExtended || false
          );
          updateGlobalVar(
            'actualEndTime',
            parsedData.endTime || chime?.endTime
          );
          break;
        default:
          break;
      }
    };

    const remoteMuteUnmuteUpdateCallback = {
      topic: MessageTopic.RemoteMuteUnmute,
      callback,
    };
    const remoteAttendeeRemoveUpdateCallback = {
      topic: MessageTopic.RemoveAttendee,
      callback,
    };
    const remoteVideoOnOffUpdateCallback = {
      topic: MessageTopic.RemoteVideoOnOff,
      callback,
    };
    const focusMessageUpdateCallback = {
      topic: MessageTopic.Focus,
      callback,
    };
    const MuteAllMessageUpdateCallback = {
      topic: MessageTopic.MuteAll,
      callback,
    };
    const screenSharePermitMessageUpdateCallback = {
      topic: MessageTopic.ScreenSharePermit,
      callback,
    };
    const endMeetingMessageUpdateCallback = {
      topic: MessageTopic.EndMeeting,
      callback,
    };
    const extendMeetingTimeCallback = {
      topic: MessageTopic.ExtendMeetingTime,
      callback,
    };

    chime?.subscribeToMessageUpdate(remoteMuteUnmuteUpdateCallback);
    chime?.subscribeToMessageUpdate(remoteAttendeeRemoveUpdateCallback);
    chime?.subscribeToMessageUpdate(remoteVideoOnOffUpdateCallback);
    chime?.subscribeToMessageUpdate(focusMessageUpdateCallback);
    chime?.subscribeToMessageUpdate(screenSharePermitMessageUpdateCallback);
    chime?.subscribeToMessageUpdate(MuteAllMessageUpdateCallback);
    chime?.subscribeToMessageUpdate(endMeetingMessageUpdateCallback);
    chime?.subscribeToMessageUpdate(extendMeetingTimeCallback);
    return () => {
      chime?.unsubscribeFromMessageUpdate(remoteMuteUnmuteUpdateCallback);
      chime?.unsubscribeFromMessageUpdate(remoteAttendeeRemoveUpdateCallback);
      chime?.unsubscribeFromMessageUpdate(remoteVideoOnOffUpdateCallback);
      chime?.unsubscribeFromMessageUpdate(focusMessageUpdateCallback);
      chime?.unsubscribeFromMessageUpdate(
        screenSharePermitMessageUpdateCallback
      );
      chime?.unsubscribeFromMessageUpdate(MuteAllMessageUpdateCallback);
      chime?.unsubscribeFromMessageUpdate(endMeetingMessageUpdateCallback);
      chime?.unsubscribeFromMessageUpdate(extendMeetingTimeCallback);
    };
  }, [meetingStatus]);
}
