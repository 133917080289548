/* eslint-disable  */

import classNames from 'classnames/bind';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { Box, Divider } from '@mui/material';

import ChimeSdkWrapper from '../chime/ChimeSdkWrapper';
import getChimeContext from '../context/getChimeContext';
import getMeetingStatusContext from '../context/getMeetingStatusContext';
import getGlobalVarContext from '../context/getGlobalVarContext';

import ClassMode from '../enums/ClassMode';
import MeetingStatus from '../enums/MeetingStatus';
import ViewMode from '../enums/ViewMode';
import Chat from './Chat';
import styles from './Classroom.css';
import ContentVideo from './ContentVideo';
import Controls from './Controls';
import Error from './Error';
import LoadingSpinner from './LoadingSpinner';
import CheckMediaPermissions from './CheckMediaPermissions';
import Roster from './Roster';
import Main from '../custom/classroom/Main';
import {
  useNotificationDispatch,
  Type as NotifType,
} from '../providers/NotificationProvider';
import RosterSliderView from './RosterSliderView';
// declare var html2canvas: any;

const cx = classNames.bind(styles);
const drawerWidth = 301;

export default function Classroom() {
  const chime: ChimeSdkWrapper | null = useContext(getChimeContext());
  const intl = useIntl();
  const notifDispatch = useNotificationDispatch();

  const { updateGlobalVar } = useContext(getGlobalVarContext());
  const { meetingStatus, errorMessage } = useContext(getMeetingStatusContext());
  const [isContentShareEnabled, setIsContentShareEnabled] =
    useState<boolean>(false);
  const [isScreenShareView, setIsScreenShareView] = useState<boolean>(false);
  const [screenShareFullView, setScreenShareFullView] =
    useState<boolean>(false);

  const [tryToReload, setTryToReload] = useState<boolean>(true);
  const [viewMode, setViewMode] = useState<number>(ViewMode.Room);
  const [openChat, setOpenChat] = useState<boolean>(false);
  const [openParticipants, setOpenParticipants] = useState<boolean>(false);
  const [rightDrawerOpen, setRightDrawerOpen] = React.useState<boolean>(false);
  const location = useLocation();
  const locationState = location?.state || null;

  // useEffect(() => {
  //   const elementToConvert = document.getElementById('content');

  //   // Use html2canvas to convert the HTML element to a canvas
  //   html2canvas(elementToConvert).then((canvas:any)=> {
  //     console.log(canvas,'---------------')
  //     // document.body.appendChild(canvas);
  //   });
  // }, []); 

  useEffect(() => {
    if (locationState) {
      const {
        meetingName,
        meetingID,
        id,
        batchId,
        userName,
        mode,
        userID,
        startTime,
        endTime,
        isRecording,
        extendTime,
        meetingType,
      }: any = locationState;
      const teacherId = id;
      const info = {
        teacherId,
        meetingID,
        meetingName,
        batchId,
        userName,
        mode,
        userID,
        startTime,
        endTime,
        isRecording,
        extendTime,
        meetingType,
      };
      updateGlobalVar('userInfo', info);
      if (mode) {
        updateGlobalVar(
          'classMode',
          mode === 'mp' ? ClassMode.Teacher : ClassMode.Student
        );
      }
    }
  }, [locationState]);

  const stopContentShare = async () => {
    await new Promise((resolve) => setTimeout(resolve, 200));
    try {
      chime?.audioVideo?.stopContentShare();
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    } finally {
      setViewMode(ViewMode.Room);
    }
  };

  // Must pass a memoized callback to the ContentVideo component using useCallback().
  // ContentVideo will re-render only when one dependency "viewMode" changes.
  // See more comments in ContentVideo.
  const onContentShareEnabled = useCallback(
    async (enabled: boolean) => {
      if (enabled) {
        await stopContentShare();
      }
      setIsContentShareEnabled(enabled);
      setIsScreenShareView(enabled);
    },
    [viewMode]
  );

  const handleDrawerLeftToggle = () => {
    if (openParticipants && !openChat) {
      closeDrawerRightToggle();
    } else {
      openDrawerRightToggle();
    }
    setOpenParticipants(!openParticipants);
  };

  const openDrawerRightToggle = () => {
    setRightDrawerOpen(true);
  };

  const closeDrawerRightToggle = () => {
    setRightDrawerOpen(false);
  };

  useEffect(() => {
    if (openChat) {
      updateGlobalVar('isChatOpen', true);
    } else {
      updateGlobalVar('isChatOpen', false);
    }
  }, [openChat]);

  // OBSERVE INTERNET CONNECTION
  const observer = {
    connectionDidBecomePoor: () => {
      notifDispatch({
        type: NotifType.POOR_INTERNET_CONNECTION,
        payload: {
          message: intl.formatMessage({ id: 'Classroom.poorConnection' }),
        },
      });
      setTimeout(() => {
        notifDispatch({
          type: NotifType.REMOVE_POOR_INTERNET_CONNECTION,
          payload: 'POOR_INTERNET_CONNECTION',
        });
      }, 15000);
    },
    connectionDidSuggestStopVideo: () => {
      notifDispatch({
        type: NotifType.POOR_INTERNET_CONNECTION,
        payload: {
          message: intl.formatMessage({ id: 'Classroom.poorConnection' }),
        },
      });
      setTimeout(() => {
        notifDispatch({
          type: NotifType.REMOVE_POOR_INTERNET_CONNECTION,
          payload: 'POOR_INTERNET_CONNECTION',
        });
      }, 15000);
    },
    videoSendDidBecomeUnavailable: () => {
      // Chime SDK allows a total of 25 simultaneous videos per meeting.
      // If you try to share more video, this method will be called.
      // See videoAvailabilityDidChange below to find out when it becomes available.
      notifDispatch({
        type: NotifType.POOR_INTERNET_CONNECTION,
        payload: {
          message: intl.formatMessage({
            id: 'Classroom.videoTileLimitExceeded',
          }),
        },
      });
      setTimeout(() => {
        notifDispatch({
          type: NotifType.REMOVE_POOR_INTERNET_CONNECTION,
          payload: 'POOR_INTERNET_CONNECTION',
        });
      }, 10000);
    },
  };

  chime?.audioVideo?.addObserver(observer);

  useEffect(() => {
    window.addEventListener('beforeunload', () => localStorage.clear());

    return () => {
      window.removeEventListener('beforeunload', () => localStorage.clear());
    };
  }, []);

  return (
    <>
      <CheckMediaPermissions
        isRetry={() => {
          setTryToReload(false);
          setTryToReload(true);
        }}
      />
      {tryToReload && (
        <Box className={cx('Mui_classroom_parent_container')}>
          {meetingStatus === MeetingStatus.Loading && <LoadingSpinner />}
          {meetingStatus === MeetingStatus.Failed && (
            <Error errorMessage={errorMessage} />
          )}
          {meetingStatus === MeetingStatus.Succeeded && (
            <Box className={cx('Mui_classroom_child_container')}>
              <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
              >
                <Main
                  rightopen={rightDrawerOpen ? 1 : 0}
                  drawerwidth={drawerWidth}
                >
                  <div className={cx('ClassRoom_left')}>
                    <div
                      className={cx('ClassRoom_contentVideoWrapper', {
                        isContentShareEnabled,
                        screenShareView: !isScreenShareView,
                        rightDrawerOpen,
                        screenShareFullView:
                          screenShareFullView && isContentShareEnabled,
                      })}
                      onDoubleClick={() => {
                        setScreenShareFullView(!screenShareFullView);
                      }}
                    >
                      <ContentVideo
                        onContentShareEnabled={onContentShareEnabled}
                      />
                    </div>
                    <div className={cx('ClassRoom_remoteVideoGroupWrapper')}>
                      <RosterSliderView
                        isContentShareEnabled={isContentShareEnabled}
                        isScreenShareView={isScreenShareView}
                        rightDrawerOpen={rightDrawerOpen}
                      />
                    </div>
                  </div>
                </Main>
                <div
                  className={cx('Mui_classroom_drawer_div')}
                  style={{ display: rightDrawerOpen ? 'block' : 'none' }}
                >
                  <div className={cx('Mui_classroom_drawer_container_div')}>
                    <div
                      className={cx({
                        ClassRoom_chat_parent_div_open: openParticipants,
                        ClassRoom_chat_parent_div_close: !openParticipants,
                        ClassRoom_chat_open_one: !openChat && openParticipants,
                        ClassRoom_chat_open_both: openChat && openParticipants,
                      })}
                    >
                      <Roster
                        closeParticipantsPanel={handleDrawerLeftToggle}
                        openChatPanel={() => setOpenChat(true)}
                      />
                    </div>
                    <Divider className={cx('Mui_classroom_header_divider')} />
                    <div
                      className={cx('ClassRoom_chat_parent_div', {
                        ClassRoom_chat_parent_div_open: openChat,
                        ClassRoom_chat_parent_div_close: !openChat,
                        ClassRoom_chat_open_one: openChat && !openParticipants,
                        ClassRoom_chat_open_both: openChat && openParticipants,
                      })}
                    >
                      <Chat
                        closeChatPanel={() => {
                          if (!openParticipants) {
                            closeDrawerRightToggle();
                          }
                          setOpenChat(false);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Box>
              <div
                className={cx('Mui_classroom_control_appbar')}
                style={
                  rightDrawerOpen
                    ? {
                        width: `calc(100% - ${drawerWidth + 16}px) !important`,
                        marginRight: `${drawerWidth}px`,
                      }
                    : {
                        width: `calc(100% - 16px) !important`,
                        marginRight: `0px`,
                      }
                }
              >
                <Controls
                  openChat={openChat}
                  openParticipants={openParticipants}
                  onClickShareButton={async (flag: boolean) => {
                    try {
                      if (flag) {
                        await chime?.audioVideo?.startContentShareFromScreenCapture();
                      } else {
                        await chime?.audioVideo?.stopContentShare();
                      }
                    } catch (err) {
                      console.log('err.....', err);
                    }
                  }}
                  onClickChatButton={(flag: boolean) => {
                    if (flag) {
                      openDrawerRightToggle();
                      setOpenChat(true);
                    } else {
                      if (!openParticipants) {
                        closeDrawerRightToggle();
                      }
                      setOpenChat(false);
                    }
                  }}
                  handleDrawerLeftToggle={handleDrawerLeftToggle}
                  isContentShareEnabled={isContentShareEnabled}
                />
              </div>
            </Box>
          )}
        </Box>
      )}
    </>
  );
}
