/* eslint-disable  */

import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';

import {
  Avatar,
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  MenuItem,
  Popover,
} from '@mui/material';
import ChimeSdkWrapper from '../chime/ChimeSdkWrapper';
import routes from '../constants/routes.json';
import getChimeContext from '../context/getChimeContext';
import getGlobalVarContext from '../context/getGlobalVarContext';
import ClassMode from '../enums/ClassMode';
import SmallAvatar from '../custom/roster/SmallAvatar';
import MoreSettings from './MoreSettings';
import styles from './Controls.css';
import MessageTopic from '../enums/MessageTopic';
import Icons from '../custom/Icons';
import Tooltip from '../custom/classroom/Tooltip';
import { ControlsPopOver } from '../custom/classroom/Popover';

const cx = classNames.bind(styles);
let timeoutId: number;

enum VideoStatus {
  Disabled,
  Loading,
  Enabled,
}

type Props = {
  onClickShareButton: (flag: boolean) => void;
  onClickChatButton: (flag: boolean) => void;
  handleDrawerLeftToggle: () => void;
  openParticipants: boolean;
  openChat: boolean;
  isContentShareEnabled: boolean;
};

export default function Controls(props: Props) {
  const {
    onClickShareButton,
    onClickChatButton,
    openChat,
    handleDrawerLeftToggle,
    openParticipants,
    isContentShareEnabled,
  } = props;
  const chime: ChimeSdkWrapper | null = useContext(getChimeContext());
  const { globalVar, updateGlobalVar } = useContext(getGlobalVarContext());
  const {
    localVideo,
    groupChatCounter,
    userInfo,
    classMode,
    screenSharePermit,
    videoPermissionAllowed,
    audioPermissionAllowed,
  } = globalVar;
  const navigate = useNavigate();
  const [muted, setMuted] = useState<boolean>(false);
  const [isScreenShared, setIsScreenShared] = useState<boolean>(false);
  const [openScreenSharePermit, setOpenScreenSharePermit] =
    useState<boolean>(false);
  const [onChat, setOnChat] = useState<boolean>(false);
  const [raised, setRaised] = useState<boolean>(false);
  const [videoStatus, setVideoStatus] = useState<number>(VideoStatus.Disabled);
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const callback = (localMuted: boolean) => {
      setMuted(localMuted);
    };

    const screenShareCb = {
      contentShareDidStart: () => {
        setIsScreenShared(true);
      },
      contentShareDidStop: () => {
        setIsScreenShared(false);
      },
    };

    chime?.audioVideo?.realtimeSubscribeToMuteAndUnmuteLocalAudio(callback);
    chime?.audioVideo?.addContentShareObserver(screenShareCb);
    return () => {
      if (chime && chime?.audioVideo) {
        chime?.audioVideo?.realtimeUnsubscribeToMuteAndUnmuteLocalAudio(
          callback
        );
        chime?.audioVideo?.removeContentShareObserver(screenShareCb);
      }
    };
  }, []);

  useEffect(() => {
    if (openChat) {
      setOnChat(true);
    } else {
      setOnChat(false);
    }
  }, [openChat]);

  useEffect(() => {
    setVideoStatus(localVideo ? VideoStatus.Enabled : VideoStatus.Disabled);
  }, [localVideo]);

  useEffect(() => {
    const attendeeId = chime?.configuration?.credentials?.attendeeId;
    if (!attendeeId) {
      return;
    }

    chime?.sendMessage(
      raised ? MessageTopic.RaiseHand : MessageTopic.DismissHand,
      attendeeId
    );

    if (raised) {
      timeoutId = window.setTimeout(() => {
        chime?.sendMessage(MessageTopic.DismissHand, attendeeId);
        setRaised(false);
      }, 10000);
    } else {
      clearTimeout(timeoutId);
    }
  }, [raised, chime?.configuration]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseScreenShareDialog = () => {
    setOpenScreenSharePermit(false);
  };

  return (
    <Box className={cx('Mui_controls_parent_container_box')}>
      <Box className={cx('Mui_controls_child_container_box')}>
        <Tooltip
          title={
            muted
              ? intl.formatMessage({ id: 'Controls.unmuteTooltip' })
              : intl.formatMessage({ id: 'Controls.muteTooltip' })
          }
          placement="bottom"
        >
          <button
            style={{ width: '83px' }}
            onClick={async () => {
              if (!audioPermissionAllowed) {
                updateGlobalVar('audioPermissionDialogShow', true);
                return;
              }
              if (muted) {
                chime?.audioVideo?.realtimeUnmuteLocalAudio();
              } else {
                chime?.audioVideo?.realtimeMuteLocalAudio();
              }
              // Adds a slight delay to close the tooltip before rendering the updated text in it
              await new Promise((resolve) => setTimeout(resolve, 10));
            }}
            className={cx(
              'Mui_controls_buttons',
              muted ? 'Controls_btn_active' : 'Controls_btn_not_active'
            )}
          >
            {muted ? (
              <>
                <Icons
                  src={'/icons/microphone_off_black.svg'}
                  alt="microphone_off_black"
                />
                <span className={cx('Controls_margin_right')}>
                  {intl.formatMessage({ id: 'Controls.unmuteTooltip' })}
                </span>
              </>
            ) : (
              <>
                <Icons
                  src={'/icons/microphone_on_white.svg'}
                  alt="microphone_on_white"
                />
                <span className={cx('Controls_margin_right')}>
                  {intl.formatMessage({ id: 'Controls.muteTooltip' })}
                </span>
              </>
            )}
          </button>
        </Tooltip>

        <Tooltip
          title={
            videoStatus === VideoStatus.Disabled
              ? intl.formatMessage({ id: 'Controls.turnOnVideoTooltip' })
              : intl.formatMessage({ id: 'Controls.turnOffVideoTooltip' })
          }
          placement="bottom"
        >
          <button
            onClick={async () => {
              // Adds a slight delay to close the tooltip before rendering the updated text in it
              await new Promise((resolve) => setTimeout(resolve, 10));
              if (!videoPermissionAllowed) {
                updateGlobalVar('videoPermissionDialogShow', true);
                return;
              }

              if (videoStatus === VideoStatus.Disabled) {
                setVideoStatus(VideoStatus.Loading);
                try {
                  if (!chime?.currentVideoInputDevice) {
                    throw new Error('currentVideoInputDevice does not exist');
                  }
                  await chime?.chooseVideoInputDevice(
                    chime?.currentVideoInputDevice
                  );
                  chime?.audioVideo?.startLocalVideoTile();
                  setVideoStatus(VideoStatus.Enabled);
                } catch (error) {
                  // eslint-disable-next-line
                  console.error(error);
                  setVideoStatus(VideoStatus.Disabled);
                }
              } else if (videoStatus === VideoStatus.Enabled) {
                setVideoStatus(VideoStatus.Loading);
                chime?.audioVideo?.stopLocalVideoTile();
                setVideoStatus(VideoStatus.Disabled);
              }
            }}
            style={{ width: '117.5px', marginLeft: '10px' }}
            className={cx(
              'Mui_controls_buttons',
              videoStatus === VideoStatus.Enabled
                ? 'Controls_btn_active'
                : 'Controls_btn_not_active'
            )}
          >
            {videoStatus === VideoStatus.Enabled ? (
              <>
                <Icons
                  src={'/icons/camera_off_black.svg'}
                  alt="camera_off_black"
                />
                <span className={cx('Controls_margin_right')}>
                  {intl.formatMessage({ id: 'Controls.stopVideo' })}
                </span>
              </>
            ) : (
              <>
                <Icons
                  src={'/icons/camera_off_white.svg'}
                  alt="camera_off_white"
                />
                <span className={cx('Controls_margin_right')}>
                  {intl.formatMessage({ id: 'Controls.startVideo' })}
                </span>
              </>
            )}
          </button>
        </Tooltip>

        <Tooltip
          title={
            isScreenShared
              ? intl.formatMessage({ id: 'Controls.shareScreenStopTooltip' })
              : intl.formatMessage({ id: 'Controls.shareScreenTooltip' })
          }
          placement="bottom"
        >
          <button
            onClick={() => {
              if (classMode === ClassMode.Teacher || screenSharePermit) {
                if (!isScreenShared) {
                  onClickShareButton(true);
                } else {
                  onClickShareButton(false);
                }
              } else {
                setOpenScreenSharePermit(true);
              }
            }}
            className={cx(
              'Mui_controls_buttons',
              isScreenShared
                ? 'Controls_sc_btn_active'
                : 'Controls_sc_btn_not_active'
            )}
          >
            {isScreenShared ? (
              <>
                <Icons
                  src={'/icons/screen_share_white.svg'}
                  alt="screen_share_white"
                />
                <span className={cx('Controls_margin_right')}>
                  {intl.formatMessage({
                    id: 'Controls.shareScreenStopTooltip',
                  })}
                </span>
              </>
            ) : (
              <>
                <Icons
                  src={'/icons/screen_share_black.svg'}
                  alt="screen_share_black"
                />
                <span className={cx('Controls_margin_right')}>
                  {intl.formatMessage({ id: 'Controls.shareScreenTooltip' })}
                </span>
              </>
            )}
          </button>
        </Tooltip>

        <Tooltip
          title={intl.formatMessage({ id: 'Controls.Chat' })}
          placement="bottom"
        >
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            badgeContent={
              <SmallAvatar
                sx={{
                  display: groupChatCounter === 0 ? 'none' : 'flex',
                  fontSize: groupChatCounter <= 99 ? '1rem' : '0.6rem',
                }}
                bgcolor={'var(--color_pink)'}
              >
                {groupChatCounter <= 99 ? groupChatCounter : '99+'}
              </SmallAvatar>
            }
          >
            <button
              style={{ width: '81px', marginLeft: '10px' }}
              onClick={() => {
                if (!onChat) {
                  setOnChat(true);
                  onClickChatButton(true);
                } else {
                  setOnChat(false);
                  onClickChatButton(false);
                }
              }}
              className={cx(
                'Mui_controls_buttons',
                onChat ? 'Controls_btn_active' : 'Controls_btn_not_active'
              )}
            >
              {onChat ? (
                <>
                  <Icons src={'/icons/chat_black.svg'} alt="chat_black" />
                  <span className={cx('Controls_margin_right')}>
                    {intl.formatMessage({ id: 'Controls.Chat' })}
                  </span>
                </>
              ) : (
                <>
                  <Icons src={'/icons/chat.svg'} alt="chat" />
                  <span className={cx('Controls_margin_right')}>
                    {intl.formatMessage({ id: 'Controls.Chat' })}
                  </span>
                </>
              )}
            </button>
          </Badge>
        </Tooltip>

        <Tooltip
          title={intl.formatMessage({ id: 'Controls.participants' })}
          placement="bottom"
        >
          <button
            style={{ width: '124.5px', marginLeft: '10px' }}
            onClick={handleDrawerLeftToggle}
            className={cx(
              'Mui_controls_buttons',
              openParticipants
                ? 'Controls_btn_active'
                : 'Controls_btn_not_active'
            )}
          >
            {openParticipants ? (
              <Icons
                src={'/icons/participants_black.svg'}
                alt="participants_black"
              />
            ) : (
              <Icons src={'/icons/participants.svg'} alt="participants" />
            )}
            <span className={cx('Controls_margin_right')}>
              {intl.formatMessage({ id: 'Controls.participants' })}
            </span>
          </button>
        </Tooltip>

        <MoreSettings />

        <Dialog
          open={openScreenSharePermit}
          onClose={handleCloseScreenShareDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {isContentShareEnabled &&
              (screenSharePermit || classMode === ClassMode.Teacher)
                ? intl.formatMessage({
                    id: 'Controls.screenShareAlreadySharedDialogMessage',
                  })
                : intl.formatMessage({
                    id: 'Controls.screenSharePermitDialogMessage',
                  })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseScreenShareDialog} autoFocus>
              {intl.formatMessage({ id: 'Controls.dialogOk' })}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Box className={cx('Mui_classroom_control_second_container')}>
        {classMode === ClassMode.Student && (
          <Tooltip
            title={
              raised
                ? intl.formatMessage({ id: 'ChatInput.dismissHandAriaLabel' })
                : intl.formatMessage({ id: 'ChatInput.raiseHandAriaLabel' })
            }
            placement="bottom"
          >
            <Avatar
              className={cx({
                Mui_controls_raised_hand: raised,
                Mui_controls_dismiss_hand: !raised,
              })}
              onClick={() => {
                setRaised(!raised);
              }}
            >
              {raised ? (
                <Icons src={'/icons/hand.svg'} alt="hand" />
              ) : (
                <Icons src={'/icons/hand_white.svg'} alt="hand_white" />
              )}
            </Avatar>
          </Tooltip>
        )}
        <Popover
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={ControlsPopOver}
        >
          {classMode === ClassMode.Teacher && (
            <MenuItem
              sx={{ fontSize: '12px' }}
              onClick={() => {
                chime?.sendMessage(MessageTopic.EndMeeting, {
                  focus: true,
                });
                setTimeout(async () => {
                  await chime?.leaveRoom(true);
                  navigate(`${routes.MAIN}?id=${userInfo.teacherId}`);
                }, 1000);
              }}
            >
              {intl.formatMessage({ id: 'Controls.EndMeeting' })}
            </MenuItem>
          )}
          <MenuItem
            sx={{ fontSize: '12px' }}
            onClick={async () => {
              await chime?.leaveRoom(false);
              navigate(routes.MAIN);
            }}
          >
            {intl.formatMessage({ id: 'Controls.LeaveMeeting' })}
          </MenuItem>
        </Popover>
        <Tooltip
          title={
            classMode === ClassMode.Teacher
              ? intl.formatMessage({ id: 'Controls.endClassroomTooltip' })
              : intl.formatMessage({ id: 'Controls.leaveClassroomTooltip' })
          }
          placement="bottom"
        >
          <Avatar
            onClick={handleClick}
            className={cx('Mui_controls_end_meeting_btn')}
          >
            <Icons src={'/icons/endmeeting.svg'} alt="endmeeting" />
          </Avatar>
        </Tooltip>
      </Box>
    </Box>
  );
}
