export function extendedTimes(extTime: number) {
  const minuteOptions = [
    {
      label: '15 minutes',
      value: 15,
    },
    {
      label: '30 minutes',
      value: 30,
    },
    {
      label: '45 minutes',
      value: 45,
    },
    {
      label: '60 minutes',
      value: 60,
    },
  ];

  const filteredOptions = minuteOptions.filter((option) => {
    const optionValue = option.value;
    return optionValue <= extTime;
  });
  return filteredOptions;
}
