/* eslint-disable  */

// import React, { Fragment } from 'react';
// import { render } from 'react-dom';
// import { AppContainer as ReactHotAppContainer } from 'react-hot-loader';

// const AppContainer = process.env.PLAIN_HMR ? Fragment : ReactHotAppContainer;

// document.addEventListener('DOMContentLoaded', () =>
//   render(
//     <AppContainer>
//       <Root />
//     </AppContainer>,
//     document.getElementById('root')
//   )
// );

import React from 'react';
import ReactDOM from 'react-dom';
import './app.global.css';
import Root from './components/Root';

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById('root')
);
