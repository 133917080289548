/* eslint-disable  */ 

import { useContext, useEffect, useState } from 'react';

import { DataMessage } from 'amazon-chime-sdk-js';
import ChimeSdkWrapper from '../chime/ChimeSdkWrapper';
import getChimeContext from '../context/getChimeContext';
import MessageTopic from '../enums/MessageTopic';
import { 
  useNotificationDispatch,
  Type as NotifType,
 } from "../providers/NotificationProvider";
import useRoster from './useRoster';

export default function useRaisedHandAttendees() {
  const chime: ChimeSdkWrapper | null = useContext(getChimeContext());
  const notifDispatch = useNotificationDispatch();
  const roster = useRoster();
  const [raisedHandAttendees, setRaisedHandAttendees] = useState<Set<unknown>>(new Set());
  const localUserId =
  chime?.meetingSession?.configuration?.credentials?.attendeeId;

  useEffect(() => {
    const realTimeRaisedHandAttendees = new Set();
    const callback = (message: DataMessage) => {
      const attendeeId = message.text();
      if (attendeeId) {
        if (message.topic === MessageTopic.RaiseHand) {
          realTimeRaisedHandAttendees.add(attendeeId);
          chime?.updateRaisedHand(attendeeId, true);
          if(attendeeId !== localUserId){
            notifDispatch({ type: NotifType.RISE_HAND, payload: { message: `${roster[attendeeId]?.name} raised a hand` }});
          }
        } else if (message.topic === MessageTopic.DismissHand) {
          realTimeRaisedHandAttendees.delete(attendeeId);
          chime?.updateRaisedHand(attendeeId, false);
          if(attendeeId !== localUserId){
            notifDispatch({ type: NotifType.DISMISS_HAND, payload: "RISE_HAND" });
          }
        }
        setRaisedHandAttendees(new Set(realTimeRaisedHandAttendees));
      }
    };
    const raiseHandMessageUpdateCallback = {
      topic: MessageTopic.RaiseHand,
      callback
    };
    const dismissHandMessageUpdateCallback = {
      topic: MessageTopic.DismissHand,
      callback
    };
    chime?.subscribeToMessageUpdate(raiseHandMessageUpdateCallback);
    chime?.subscribeToMessageUpdate(dismissHandMessageUpdateCallback);
    return () => {
      chime?.unsubscribeFromMessageUpdate(raiseHandMessageUpdateCallback);
      chime?.subscribeToMessageUpdate(dismissHandMessageUpdateCallback);
    };
  }, []);
  return raisedHandAttendees;
}
