/* eslint-disable  */

import React, { ReactNode, useState } from 'react';
import getGlobalVarContext from '../context/getGlobalVarContext';
import ClassMode from '../enums/ClassMode';

type Props = {
  children: ReactNode;
};

export default function GlobalVarProvider(props: Props) {
  const { children } = props;
  const GlobalVarContext = getGlobalVarContext();
  const [globalVar, setGlobalVar] = useState<{
    localVideo: boolean;
    isChatOpen: boolean;
    groupChatCounter: number;
    userInfo: {};
    classMode: ClassMode;
    screenSharePermit: boolean;
    activeChatAttendeeId: string;
    turnOnFocus: boolean;
    videoPermissionAllowed: boolean;
    audioPermissionAllowed: boolean;
    videoPermissionDialogShow: boolean;
    audioPermissionDialogShow: boolean;
    isTimeExtended: boolean | null;
    actualEndTime: string | null;
  }>({
    localVideo: false,
    isChatOpen: false,
    groupChatCounter: 0,
    userInfo: {},
    classMode: ClassMode.Student,
    screenSharePermit: false,
    activeChatAttendeeId: '',
    turnOnFocus: false,
    videoPermissionAllowed: false,
    audioPermissionAllowed: false,
    videoPermissionDialogShow: false,
    audioPermissionDialogShow: false,
    isTimeExtended: null,
    actualEndTime: null,
  });

  const updateGlobalVar = (key: string, value: any) => {
    switch (key) {
      case 'localVideo':
        setGlobalVar((oldData) => ({ ...oldData, localVideo: value }));
        break;
      case 'isChatOpen':
        setGlobalVar((oldData) => ({ ...oldData, isChatOpen: value }));
        break;
      case 'groupChatCounter':
        setGlobalVar((oldData) => ({ ...oldData, groupChatCounter: value }));
        break;
      case 'userInfo':
        setGlobalVar((oldData) => ({ ...oldData, userInfo: value }));
        break;
      case 'classMode':
        setGlobalVar((oldData) => ({ ...oldData, classMode: value }));
        break;
      case 'screenSharePermit':
        setGlobalVar((oldData) => ({ ...oldData, screenSharePermit: value }));
        break;
      case 'activeChatAttendeeId':
        setGlobalVar((oldData) => ({
          ...oldData,
          activeChatAttendeeId: value,
        }));
        break;
      case 'turnOnFocus':
        setGlobalVar((oldData) => ({ ...oldData, turnOnFocus: value }));
        break;
      case 'videoPermissionAllowed':
        setGlobalVar((oldData) => ({
          ...oldData,
          videoPermissionAllowed: value,
        }));
        break;
      case 'audioPermissionAllowed':
        setGlobalVar((oldData) => ({
          ...oldData,
          audioPermissionAllowed: value,
        }));
        break;
      case 'videoPermissionDialogShow':
        setGlobalVar((oldData) => ({
          ...oldData,
          videoPermissionDialogShow: value,
        }));
        break;
      case 'audioPermissionDialogShow':
        setGlobalVar((oldData) => ({
          ...oldData,
          audioPermissionDialogShow: value,
        }));
        break;
      case 'isTimeExtended':
        setGlobalVar((oldData) => ({
          ...oldData,
          isTimeExtended: value,
        }));
        break;
      case 'actualEndTime':
        setGlobalVar((oldData) => ({
          ...oldData,
          actualEndTime: value,
        }));
        break;
      default:
        break;
    }
  };

  return (
    <GlobalVarContext.Provider value={{ globalVar, updateGlobalVar }}>
      {children}
    </GlobalVarContext.Provider>
  );
}
