/* eslint-disable  */

import { VideoTileState } from 'amazon-chime-sdk-js';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';

import ChimeSdkWrapper from '../chime/ChimeSdkWrapper';
import getChimeContext from '../context/getChimeContext';
import getGlobalVarContext from '../context/getGlobalVarContext';
import RemoteVideo from './RemoteVideo';
import useRoster from '../hooks/useRoster';
import RosterAttendeeType from '../types/RosterAttendeeType';
import RosterLayout from './RosterLayout';
import LocalVideo from './LocalVideo';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
} from '@mui/material';
import Icons from '../custom/Icons';
import { countDownTimer } from '../utils/countDownTimer';
import styles from './RosterSliderView.css';
import routes from '../constants/routes.json';
import { calculateRowsAndColumns } from '../utils/rosterSize';
import commonob from '../constants/common.json';
import { extendedTimes } from '../utils/times';
import ClassMode from '../enums/ClassMode';
import localStorageKeys from '../constants/localStorageKeys.json';
import MessageTopic from '../enums/MessageTopic';

const MAX_REMOTE_VIDEOS = 16;
let contentShareEnabled = false;
let endTime: number | null | undefined;
let isMeetingExtended: boolean = false;

const cx = classNames.bind(styles);

type Props = {
  isContentShareEnabled: boolean;
  isScreenShareView: boolean;
  rightDrawerOpen: boolean;
};
export default function RosterSliderView(props: Props) {
  const { isContentShareEnabled, rightDrawerOpen } = props;
  const chime: ChimeSdkWrapper | null = useContext(getChimeContext());
  const { globalVar } = useContext(getGlobalVarContext());
  const { userInfo, classMode, actualEndTime, isTimeExtended } = globalVar;
  const [visibleIndices, setVisibleIndices] = useState<{
    [index: string]: { boundAttendeeId: string };
  }>({});
  const roster = useRoster();
  const navigate = useNavigate();
  const videoElements: HTMLVideoElement[] = [];
  const tiles: { [index: number]: number } = {};
  const currentUser = chime?.configuration?.credentials?.attendeeId;
  const [videoAttendees, setVideoAttendees] = useState<Set<unknown>>(new Set());
  const [showTimeExtensionLoading, setShowTimeExtensionLoading] =
    useState<boolean>(false);
  const [attendeeIds, setAttendeeIds] = useState<string[]>([]);
  const [scrollLength, setScrollLength] = useState<number>(0);
  const [maxScrollLength, setMaxScrollLength] = useState<number>(0);
  const timerElement = useRef(null);
  const [openDailog, setOpenDailog] = useState(false);
  const [openDailog1, setOpenDailog1] = useState(false);
  const [openDailog2, setOpenDailog2] = useState(false);

  const [extTime, setExtTime] = useState(15);

  useEffect(() => {
    contentShareEnabled = isContentShareEnabled;
  }, [isContentShareEnabled]);

  useEffect(() => {
    if (actualEndTime && isTimeExtended) {
      endTime = actualEndTime;
      isMeetingExtended = isTimeExtended;
    }
  }, [globalVar]);

  useEffect(() => {
    const mt = timerElement.current! as HTMLElement;
    endTime = chime?.endTime;
    isMeetingExtended = chime?.isMeetingExtended || false;
    if (mt && endTime) {
      const timerTag = document.createElement('span');
      timerTag.classList.add('Mui_roster_slider_red_color');
      const infoTag = document.createElement('span');
      infoTag.innerHTML = 'Your meeting will end in ';
      mt.appendChild(infoTag);
      mt.appendChild(timerTag);
      setInterval(async function () {
        const { time, shouldShow, shouldWarn } = countDownTimer(
          endTime as number,
          timerTag
        );
        if (!time && classMode === ClassMode.Teacher) {
          await chime?.leaveRoom(true);
          navigate(`${routes.MAIN}?id=${userInfo.teacherId}`);
        }
        if (shouldShow) {
          mt.style.display = 'block';
        } else {
          mt.style.display = 'none';
        }
        if (shouldWarn) {
          if (!isMeetingExtended && classMode === ClassMode.Teacher) {
            setOpenDailog(true);
            isMeetingExtended = true;
          }
        }
      }, 1000);
    }
  }, []);

  const memoizedValues = useMemo(() => {
    const activeAttendee = Object.keys(roster);
    const filteredArray = activeAttendee
      .filter((item: string) => item !== currentUser)
      .filter((attendeeId: string) => !!roster[attendeeId].name);

    let attdLength = filteredArray.length;

    if (contentShareEnabled) {
      attdLength = attdLength + 1;
    }

    const { columns } = calculateRowsAndColumns(
      attdLength <= 0 ? 1 : attdLength,
      4
    );

    const newAttendeeIds = [...filteredArray];
    const newMaxScrollLength = columns * 796;

    return [newAttendeeIds, newMaxScrollLength];
  }, [roster, currentUser, contentShareEnabled]);

  useEffect(() => {
    setAttendeeIds(memoizedValues[0] as string[]);
    setMaxScrollLength(memoizedValues[1] as number);
  }, [memoizedValues]);

  // useEffect(() => {
  //   const activeAttendee = Object.keys(roster);
  //   const filteredArray = activeAttendee
  //     .filter((item: string) => item !== currentUser)
  //     .filter((attendeeId: string) => !!roster[attendeeId].name);
  //   setAttendeeIds([...filteredArray]);
  //   let attdLength = filteredArray.length;

  //   if (contentShareEnabled) {
  //     attdLength = attdLength + 1;
  //   }
  //   const { columns } = calculateRowsAndColumns(
  //     attdLength <= 0 ? 1 : attdLength,
  //     4
  //   );
  //   setMaxScrollLength(columns * 796);
  // }, [roster, contentShareEnabled]);

  const acquireVideoIndex = (tileId: number): number => {
    for (let index = 0; index < MAX_REMOTE_VIDEOS; index += 1) {
      if (tiles[index] === tileId) {
        return index;
      }
    }
    for (let index = 0; index < MAX_REMOTE_VIDEOS; index += 1) {
      if (!(index in tiles)) {
        tiles[index] = tileId;
        return index;
      }
    }
    throw new Error('no tiles are available');
  };

  const releaseVideoIndex = (tileId: number): number => {
    for (let index = 0; index < MAX_REMOTE_VIDEOS; index += 1) {
      if (tiles[index] === tileId) {
        delete tiles[index];
        return index;
      }
    }
    return -1;
  };

  useEffect(() => {
    // remove tile id when attendee leave --sanjay balai
    const tileIds: { [tileId: number]: string } = {};
    const realTimeVideoAttendees = new Set();
    const removeTileId = (tileId: number): void => {
      const removedAttendeeId = tileIds[tileId];
      delete tileIds[tileId];
      realTimeVideoAttendees.delete(removedAttendeeId);
      setVideoAttendees(new Set(realTimeVideoAttendees));
    };

    chime?.audioVideo?.addObserver({
      videoTileDidUpdate: (tileState: VideoTileState): void => {
        if (
          !tileState.boundAttendeeId ||
          tileState.localTile ||
          tileState.isContent ||
          !tileState.tileId
        ) {
          return;
        }
        const index = acquireVideoIndex(tileState.tileId);
        chime?.audioVideo?.bindVideoElement(
          tileState.tileId,
          videoElements[index]
        );
        setVisibleIndices((previousVisibleIndices) => ({
          ...previousVisibleIndices,
          [index]: {
            boundAttendeeId: tileState.boundAttendeeId,
          },
        }));
        // code for audio/video enable or not start
        if (tileState.active) {
          tileIds[tileState.tileId] = tileState.boundAttendeeId;
          realTimeVideoAttendees.add(tileState.boundAttendeeId);
          setVideoAttendees(new Set(realTimeVideoAttendees));
        } else {
          removeTileId(tileState.tileId);
        }
      },
      videoTileWasRemoved: (tileId: number): void => {
        const index = releaseVideoIndex(tileId);
        setVisibleIndices((previousVisibleIndices) => ({
          ...previousVisibleIndices,
          [index]: null,
        }));
        removeTileId(tileId);
      },
    });
  }, []);

  const handleClose = () => {
    setOpenDailog(false);
    setOpenDailog1(false);
    setOpenDailog2(false);
  };

  const handleExtendTime = () => {
    if (chime?.endTime) {
      setShowTimeExtensionLoading(true);
      const ttlTime = Number(chime?.endTime) + extTime * 60;
      const requestOptions = {
        method: 'POST',
      };
      fetch(
        `${commonob.getBaseUrl}extendMeetingTime?title=${chime.title}&endTime=${ttlTime}&extendedTime=${extTime}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          const joinInfo = JSON.parse(
            localStorage.getItem(localStorageKeys.MEETING_CONFIG) as string
          );
          joinInfo.EndTime = result.Attributes.EndTime.S;
          joinInfo.IsMeetingExtended = result.Attributes.IsMeetingExtended.S;
          localStorage.setItem(
            localStorageKeys.MEETING_CONFIG,
            JSON.stringify(joinInfo)
          );
          endTime = result.Attributes.EndTime.S;
          chime?.sendMessage(MessageTopic.ExtendMeetingTime, {
            data: JSON.stringify({
              endTime: result.Attributes.EndTime.S,
              isMeetingExtended: result.Attributes.IsMeetingExtended.S,
              extTime: extTime,
            }),
          });

          setOpenDailog2(true);
          setOpenDailog(false);
          setOpenDailog1(false);
          setShowTimeExtensionLoading(false);
        })
        .catch((error) => {
          console.log('error', error);
          setShowTimeExtensionLoading(false);
        });
    }
  };

  return (
    <>
      <Dialog
        open={openDailog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className='custom-modal'
      >
        <DialogTitle id="alert-dialog-title">
          <div className='close-dv'>
            <button className='btn btn-close' onClick={handleClose}><img src="/icons/close-icon.svg" alt="img" className='img-close' /></button>
          </div>
        </DialogTitle>
        <div className='img-div'>
          <img src="/icons/stopwatch.png" alt="img" className='img-fluid' />
        </div>
        <div className='desc-text'>
          <h5>Your meeting is ending in <b>10 mins,</b></h5>
          <h5 className='color-text'>do you want to extend the meeting?</h5>
        </div>
        <DialogActions>
          <Button className='btn btn-outline' onClick={handleClose} autoFocus>
            No
          </Button>
          <Button
            onClick={() => {
              setOpenDailog1(true);
              setOpenDailog(false);
              setOpenDailog2(false);
            }}
            className='btn btn-fill'
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDailog1}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className='custom-modal'
      >
        <DialogTitle id="alert-dialog-title">
          <div className='close-dv'>
            <button className='btn btn-close' onClick={handleClose}><img src="/icons/close-icon.svg" alt="img" className='img-close' /></button>
          </div>
        </DialogTitle>
        <div className='img-div'>
          <img src="/icons/stopwatch.png" alt="img" className='img-fluid' />
        </div>
        <div className='desc-text desc-text02'>
          <h5>Your meeting is ending in <b>10 mins,</b></h5>
          <h5 className='color-text'>do you want to extend the meeting?</h5>
        </div>

        {Number(userInfo.extendTime) >= 15 && (
          <DialogContent className='dialog-content-div' sx={{ paddingTop: '10px !important' }}>
            <DialogContentText id="alert-dialog-description">
              {extendedTimes(userInfo.extendTime).length > 0 && (
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={extTime}
                    label="Minutes"
                    onChange={(e) => {
                      setExtTime(Number(e.target.value));
                    }}
                  >
                    {extendedTimes(userInfo.extendTime).map((quality) => {
                      return (
                        <MenuItem
                          key={`${quality.value}`}
                          sx={{ justifyContent: 'space-between !important' }}
                          value={quality.value}
                          onClick={async () => { }}
                        >
                          {quality.label}s
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <>
            <Button
              onClick={handleExtendTime}
              disabled={showTimeExtensionLoading}
              className='btn btn-outline'
            >
              Confirm
            </Button>
            <Button onClick={handleClose} className='btn btn-fill' autoFocus>
              Cancel
            </Button>
          </>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDailog2}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className='custom-modal'
      >
        <DialogTitle id="alert-dialog-title">
          <div className='close-dv'>
            <button className='btn btn-close' onClick={handleClose}><img src="/icons/close-icon.svg" alt="img" className='img-close' /></button>
          </div>
        </DialogTitle>
        <div className='img-div'>
          <img src="/icons/success-icon.svg" alt="img" className='img-fluid' />
        </div>
        <div className='desc-text'>
          <h5>You have successfully extended</h5>
          <h5>the meeting to more <b>{extTime} mins</b></h5>
        </div>
      </Dialog>
      <Box className={cx('Mui_roster_slider_parent_container')}>
        {attendeeIds.length === 0 && (
          <Box className={cx('Mui_roster_slider_local_video_container')}>
            <LocalVideo
              view={'grid'}
              activeSpeaker={roster[currentUser!]?.active}
            />
          </Box>
        )}

        <Box
          className={cx('Mui_roster_slider_main_container', {
            Mui_roster_slider_display_none: attendeeIds.length === 0,
            Mui_roster_slider_display_flex: attendeeIds.length !== 0,
          })}
        >
          <Button
            className={cx('Mui_roster_slider_white_color')}
            disabled={scrollLength >= 796 ? false : true}
            onClick={() => {
              let sl = scrollLength;
              if (scrollLength > 0) {
                sl = sl - 796;
                if (sl <= 0) {
                  sl = 0;
                }
                setScrollLength(sl);
              }
              let element = document.getElementById('tileView');
              if (element) {
                element.style.overflow = 'scroll';
                element.scrollLeft = sl;
                element.style.overflow = 'hidden';
              }
            }}
          >
            <div
              className={cx('Mui_roster_slider_left_btn', {
                Mui_roster_slider_display_none: scrollLength <= 796,
                Mui_roster_slider_display_flex: scrollLength >= 796,
              })}
            >
              <Icons src={'/icons/left_arrow.svg'} alt="left_arrow" />
            </div>
          </Button>
          <div id="tileView" className={cx('Mui_roster_slider_tileview')}>
            <div
              id="scrollview"
              className={cx('Mui_roster_slider_sliderview')}
              style={{
                width: `${maxScrollLength}px`,
                //if not scroll proper remove minWidth and justifyContent
                justifyContent: maxScrollLength > 796 ? 'flex-start' : 'center',
              }}
            >
              <div
                className={'ClassRoom_meeting_timer'}
                id="meeting_timer"
                ref={timerElement}
              ></div>
              <div
                className={cx({
                  Mui_roster_slider_active_local_video_view:
                    roster[currentUser!]?.active && !isContentShareEnabled,
                  Mui_roster_slider_not_active_local_video_view:
                    !roster[currentUser!]?.active,
                })}
                style={
                  roster[currentUser!]?.active && !isContentShareEnabled
                    ? {
                      width: rightDrawerOpen ? 'calc(100% - 301px)' : '100%',
                    }
                    : {
                      width: '195px',
                      margin: '0px 2px',
                    }
                }
                onClick={() => {
                  if (!isContentShareEnabled) {
                    chime?.updateActiveSpeaker(currentUser as string);
                  }
                }}
              >
                {attendeeIds.length !== 0 && (
                  <LocalVideo
                    activeSpeaker={roster[currentUser!]?.active}
                    view={
                      roster[currentUser!]?.active && !isContentShareEnabled
                        ? 'grid'
                        : 'activeSpeaker'
                    }
                  />
                )}
              </div>
              {Array.from(Array(MAX_REMOTE_VIDEOS).keys()).map((key, index) => {
                const visibleIndex = visibleIndices[index];
                const attendeeId = visibleIndex
                  ? visibleIndex.boundAttendeeId
                  : null;

                const activeSpeaker = visibleIndex
                  ? roster[visibleIndex.boundAttendeeId]?.active
                  : false;
                return (
                  <div
                    key={key}
                    className={cx({
                      Mui_roster_slider_active_local_video_view:
                        activeSpeaker && !isContentShareEnabled,
                      Mui_roster_slider_not_active_local_video_view:
                        !activeSpeaker,
                    })}
                    style={
                      activeSpeaker && !isContentShareEnabled
                        ? {
                          width: rightDrawerOpen
                            ? 'calc(100% - 301px)'
                            : '100%',
                        }
                        : {
                          width: '195px',
                          display: visibleIndex ? 'block' : 'none',
                          margin: '0px 2px',
                        }
                    }
                    onClick={() => {
                      if (!isContentShareEnabled) {
                        chime?.updateActiveSpeaker(attendeeId as string);
                      }
                    }}
                  >
                    <RemoteVideo
                      key={key}
                      enabled={!!visibleIndex}
                      videoElementRef={useCallback(
                        (element: HTMLVideoElement | null) => {
                          if (element) {
                            videoElements[index] = element;
                          }
                        },
                        []
                      )}
                      attendeeId={attendeeId}
                      activeSpeaker={activeSpeaker}
                      view={
                        activeSpeaker && !isContentShareEnabled
                          ? 'grid'
                          : 'activeSpeaker'
                      }
                    />
                  </div>
                );
              })}
              {attendeeIds.map((key: string) => {
                let rosterAttendee: RosterAttendeeType = {};

                if (roster) {
                  rosterAttendee = roster[key];
                }
                // const activeSpeaker = attendeeIdFullScreen === key ? true : false;
                const activeSpeaker = roster[key]?.active
                  ? roster[key]?.active
                  : false;
                if (!videoAttendees.has(key)) {
                  return (
                    <div
                      className={cx({
                        Mui_roster_slider_active_local_video_view:
                          activeSpeaker && !isContentShareEnabled,
                        Mui_roster_slider_not_active_local_video_view:
                          !activeSpeaker,
                      })}
                      style={
                        activeSpeaker && !isContentShareEnabled
                          ? {
                            width: rightDrawerOpen
                              ? 'calc(100% - 301px)'
                              : '100%',
                          }
                          : {
                            width: '195px',
                            margin: '0px 2px',
                          }
                      }
                      key={key}
                      onClick={() => {
                        if (!isContentShareEnabled) {
                          chime?.updateActiveSpeaker(key as string);
                        }
                      }}
                    >
                      <RosterLayout
                        key={key}
                        attendeeId={key}
                        activeSpeaker={activeSpeaker}
                        name={rosterAttendee?.name || ''}
                        view={
                          activeSpeaker && !isContentShareEnabled
                            ? 'grid'
                            : 'activeSpeaker'
                        }
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </div>
          <Button
            className={cx('Mui_roster_slider_white_color')}
            disabled={
              scrollLength >= maxScrollLength - 796 ||
                (scrollLength >= 0 && maxScrollLength <= 796)
                ? true
                : false
            }
            onClick={() => {
              let sl = scrollLength;
              if (scrollLength >= 0) {
                sl += 796;
                if (sl >= maxScrollLength) {
                  sl = maxScrollLength;
                }
                setScrollLength(sl);
              }
              let element = document.getElementById('tileView');
              if (element) {
                element.style.overflow = 'scroll';
                element.scrollLeft = sl;
                element.style.overflow = 'hidden';
              }
            }}
          >
            <div
              className={cx('Mui_roster_slider_left_btn', {
                Mui_roster_slider_display_none:
                  scrollLength >= maxScrollLength - 796 ||
                  (scrollLength >= 0 && maxScrollLength <= 796),
                Mui_roster_slider_display_flex: !(
                  scrollLength >= maxScrollLength - 796 ||
                  (scrollLength >= 0 && maxScrollLength <= 796)
                ),
              })}
            >
              <Icons src={'/icons/right_arrow.svg'} alt="right_arrow" />
            </div>
          </Button>
        </Box>
      </Box>
    </>
  );
}
